<template>
  <StorageInfo class="mx-44"/>
</template>

<script>
import { useStore } from "vuex";
import StorageInfo from "@/components/storages/editStorage/StorageInfo.vue";
export default {
  name: 'AddStorage',
  components: { StorageInfo },
  setup() {
    const store = useStore()
    store.commit("resetStorage")
    store.dispatch("fetchUserVenueNames")
  },
  mounted() {
    this.$store.commit('resetDynamicTableState')
  }
}
</script>