<template>
  <div class="mt-10 lg:mx-56 sm:mx-10 mx-4">
    <form @submit.prevent="onSubmit">
      <div class="shadow sm:rounded-md">
        <div class="bg-white py-6 px-4 space-y-6 sm:p-6 dark:bg-primaryDark">
          <div class="">
            <h2 class="text-2xl font-semibold text-gray-900 dark:text-white">Storage</h2>
            <p class="mt-1 text-sm text-gray-500 dark:text-gray-300">This information will be displayed publicly so be careful what you share.</p>
          </div>
          <div class="grid grid-cols-3 gap-6 dark:text-white">

            <div class="col-span-3 sm:col-span-2">
              <label for="name" class="block text-sm font-medium text-gray-700 dark:text-white">
                Storage Name
              </label>
              <div class="mt-1 rounded-md shadow-sm flex">
                <input v-model="state.name" type="text" name="name" id="name" autocomplete="" class="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-md sm:text-sm border-gray-300 dark:bg-primaryDark" />
              </div>
              <VuelidateErrorDisplay :errors="v$.name.$errors" />
            </div>

            <div class="col-span-6 sm:col-span-3">
              <label for="venue" class="block text-sm font-medium text-gray-700 dark:text-white">Place</label>
              <select v-model="state.venueId" id="venue" name="venue" autocomplete="venue" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-primaryDark">
                <option v-for="venue in venues" :key="venue.id" :value="venue.id">{{ venue.name }}</option>
              </select>
              <VuelidateErrorDisplay :errors="v$.venueId.$errors" />
            </div>

            <div class="col-span-6 sm:col-span-3">
              <label for="space" class="block text-sm font-medium text-gray-700 dark:text-white">Space</label>
              <select v-model="state.spaceId" id="space" name="space" autocomplete="space" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-primaryDark">
                <option v-for="space in spaces" :key="space.id" :value="space.id">{{ space.name }}</option>
              </select>
              <VuelidateErrorDisplay :errors="v$.spaceId.$errors" />
            </div>

            <div class="col-span-6 sm:col-span-3">
              <ParentSpaceStorageDropdownTree label="Parent Storage" :venueId="state.venueId" :spaceId="state.spaceId" @update="state.parentId = $event.id" />
            </div>

          </div>
        </div>
        <div class="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-primaryDark">
          <button type="submit" class="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, reactive, ref, watch } from "vue";
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import VuelidateErrorDisplay from "../../widgets/VuelidateErrorDisplay.vue"
import ParentSpaceStorageDropdownTree from "../../inputs/ParentSpaceStorageDropdownTree.vue"

export default {
  name: "StorageInfo",
  components: { VuelidateErrorDisplay, ParentSpaceStorageDropdownTree },
  setup(){
    const store = useStore()
    store.dispatch("fetchUserVenueNames")

    const state = reactive({
      name: "",
      parentId: "",
      spaceId: "",
      venueId: "",
    })
    const rules = {
      name: { required },
      spaceId: { required },
      venueId: { required },
    }
    const v$ = useVuelidate(rules, state, {$lazy: true})

    watch(state, () => {
      if (state.venueId != "")
        store.dispatch("fetchPlaceSpaceStorageChildren", { id: state.venueId, type: "venue" })
    })

    return {
      v$,
      state,
      store,
      levelAccess: ref(""),
      spaceTypes: computed(() => store.getters.getSpaceTypes),
      spaces: computed(() => store.getters.getVenueSpaces(state.venueId)),
      venues: computed(() => store.getters.getUserVenueNames),
    }
  },
  methods: {
    async onSubmit() {
      this.v$.$touch()
      if (this.v$.$error) return
      await this.$store.dispatch('createStorage', this.state)
      await this.$router.push({ name: 'Spaces' })
    }
  }
}
</script>
