<template>
    <ThingInfo title="Add Thing" class="mt-10 lg:mx-56 sm:mx-10 mx-4" />
</template>

<script setup>
import { onMounted } from "vue";
import { useStore } from "vuex";
import ThingInfo from "@/components/things/editThing/ThingInfo.vue";

const store = useStore()
store.commit("resetStorage")
store.dispatch("fetchUserVenueNames")

onMounted(() => {
    store.commit('resetDynamicTableState')
})
</script>
