<template>
  <SpaceInfo class="mt-10 lg:mx-56 sm:mx-10 mx-4"/>
</template>

<script>
import { useStore } from "vuex";
import SpaceInfo from "@/components/spaces/editSpace/SpaceInfo.vue";
export default {
  name: 'AddSpace',
  components: { SpaceInfo },
  setup() {
    const store = useStore()
    store.commit("resetSpace")
  },
  mounted() {
    this.$store.commit('resetDynamicTableState')
  }
}
</script>