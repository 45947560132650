<template>
  <div class="mt-10 lg:mx-56 sm:mx-10 mx-4">
    <form @submit.prevent="onSubmit">
      <div class="shadow sm:rounded-md sm:overflow-hidden">
        <div class="bg-white py-6 px-4 space-y-6 sm:px-6 dark:bg-primaryDark">
          <div class="">
            <h2 class="text-2xl font-semibold text-gray-900 dark:text-white">Space</h2>
            <p class="mt-1 text-sm text-gray-500 dark:text-gray-300">This information will be displayed publicly so be careful what you share.</p>
          </div>
          <div class="grid grid-cols-6 gap-6 dark:text-white">
            <div class="col-span-6 sm:col-span-3">
              <label for="name" class="block text-sm font-medium text-gray-700 dark:text-white">
                Space Name
              </label>
              <div class="mt-1 rounded-md shadow-sm flex">
                <input v-model="state.name" type="text" name="name" id="name" autocomplete="" class="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-md sm:text-sm border-gray-300 dark:bg-primaryDark" />
              </div>
              <VuelidateErrorDisplay :errors="v$.name.$errors" />
            </div>

              <!-- TODO:  We might want to save the venue name to the
                          reactive 'state' object. Currently we are
                          only saving the venue ID.
              -->
              <div class="col-span-6 sm:col-span-3">
                <label for="venue" class="block text-sm font-medium text-gray-700 dark:text-white">Belongs to</label>
                <select v-model="state.venueId" id="venue" name="venue" autocomplete="venue" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-primaryDark">
                  <option v-for="(venue) in venues" :key="venue.id" :value="venue.id">{{venue.name}}</option>
                </select>
              </div>
            <div class="col-span-6 sm:col-span-6 dark:text-white">
              <label for="description" class="block text-sm font-medium text-gray-700 dark:text-white">
                Description
              </label>
              <div class="mt-1">
                <textarea v-model="state.description" id="description" name="description" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md dark:bg-primaryDark" />
              </div>
              <VuelidateErrorDisplay :errors="v$.description.$errors" />
            </div>


            <div class="col-span-6 sm:col-span-3 dark:text-white">
              <label for="spaceType" class="block text-sm font-medium text-gray-700 dark:text-white">Space Type</label>
              <select v-model="state.spaceType" id="spaceType" name="spaceType" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-primaryDark">
                <option v-for="(spaceType, idx) in spaceTypes" :key="idx">{{spaceType}}</option>
              </select>
            </div>

            <div class="col-span-3 sm:col-span-3">
              <label for="maxCapacity" class="block text-sm font-medium text-gray-700 dark:text-white">
                Max capacity
              </label>
              <div class="mt-1 rounded-md shadow-sm flex">
                <input v-model="state.maxCapacity" type="number" min="0" name="maxCapacity" id="maxCapacity" autocomplete="maxCapacity" class="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-md sm:text-sm border-gray-300 dark:bg-primaryDark" />
              </div>
            </div>

            <div class="col-span-3 sm:col-span-2">
              <label for="level" class="block text-sm font-medium text-gray-700 dark:text-white">
                Level
              </label>
              <div class="mt-1 rounded-md shadow-sm flex">
                <input v-model="state.level" type="number" name="level" id="level" autocomplete="level" class="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-md sm:text-sm border-gray-300 dark:bg-primaryDark" />
              </div>
            </div>

            <div class="col-span-3 sm:col-span-2">
              <label for="roomNumber" class="block text-sm font-medium text-gray-700 dark:text-white">
                Room Number
              </label>
              <div class="mt-1 rounded-md shadow-sm flex">
                <input v-model="state.roomNumber" type="text" name="roomNumber" id="roomNumber" class="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-md sm:text-sm border-gray-300 dark:bg-primaryDark" />
              </div>
            </div>
            <div class="col-span-3 sm:col-span-2">
              <label for="area" class="block text-sm font-medium text-gray-700 dark:text-white">
                Square Meters
              </label>
              <div class="mt-1 rounded-md shadow-sm flex">
                <input v-model="state.area" type="number" name="area" id="area" class="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-md sm:text-sm border-gray-300 dark:bg-primaryDark" />
              </div>
            </div>

            <div class="col-span-6 sm:col-span-3 dark:text-white">
              <label for="levelAccess" class="block text-sm font-medium text-gray-700 dark:text-white">Access</label>
              <select v-model="state.access" id="levelAccess" name="levelAccess" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-primaryDark">
                <option :value="true">Level Access</option>
                <option :value="false">Non-Level Access</option>post

              </select>
            </div>

            <div v-if="!state.access" class="col-span-6 sm:col-span-3 dark:text-white">
              <label for="accessDescription" class="block text-sm font-medium text-gray-700 dark:text-white">
                Access Description
              </label>
              <div class="mt-1">
                <textarea v-model="state.accessDesc" id="accessDescription" name="accessDescription" rows="1" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md dark:bg-primaryDark" />
              </div>
            </div>


          </div>
        </div>
        <div class="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-primaryDark">
          <button type="submit" class="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, reactive, ref } from "vue";
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import VuelidateErrorDisplay from "../../widgets/VuelidateErrorDisplay.vue"

export default {
  name: "SpaceInfo",
  components: { VuelidateErrorDisplay },
  setup(){
    const store = useStore()

    store.dispatch("fetchUserVenueNames")

    const state = reactive({
      name: "",
      description: "",
      // TODO:  venue is the name of the venue the space belongs to.
      //        We are currently only saving the venueId
      // venue: "",
      venueId: "",
      spaceType: "",
      maxCapacity: "",
      level: "",
      roomNumber: "",
      // access tells us if a person in a wheelchair or with
      // mobility impairments can access the space without going up
      // or down any steps, stairs or escalator. Can be True or False
      access: "",
      // accessDesc is a text field that explains how space
      // can be accessed. This is only used if access is False
      accessDesc: "",
      area: "", // square meters

    })
    const rules = {
      name: { required },
      spaceType: { required },
      description: { required },
    }
    const v$ = useVuelidate(rules, state, {$lazy: true})

    console.log("state", state)
    return {
      v$,
      state,
      store,
      levelAccess: ref(""),
      spaceTypes: computed(() => store.getters.getSpaceTypes),
      // NOTE:  We are using getCalVenues since getVenues is
      //        not working at the moment.
      venues: computed(() => store.getters.getUserVenueNames),
    }
  },
  methods: {
    async onSubmit() {
      this.v$.$touch()
      if (this.v$.$error) return
      await this.$store.dispatch('createSpace', this.state)
      await this.$router.push({ name: 'Spaces', params: { id: this.$store.getters.getSpaceId }})
    }
  }
}
</script>

<style scoped>

</style>